import i18next from 'i18next';
import i18nextChainedBackend from 'i18next-chained-backend';
import i18nextLocalStorageBackend from 'i18next-localstorage-backend';
import i18nextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// Matches string that is wrapped with ${} just like a js interpolation
// ie: Hey this test is ${color} -> ${color} can be replaced by 'green', 'blue', etc.
const INTERPOLATION_REGEX = /\${?([\w\d?.]*)}/gim;

const init = async (brand: string): Promise<void> => {
  if (i18next.options?.defaultNS !== brand) {
    await i18next
      .use(i18nextChainedBackend)
      .use(initReactI18next)
      .init(
        {
          lng: 'es', // if we are using a language detector, do not define the lng option
          ns: ['common', brand],
          defaultNS: brand,
          fallbackLng: false,
          debug: false,
          interpolation: {
            escapeValue: false,
          },
          backend: {
            backends: [i18nextLocalStorageBackend, i18nextHttpBackend],
            backendOptions: [
              {
                expirationTime: 60,
              },
              {
                loadPath: '/locales/{{ns}}/{{lng}}.json',
              },
            ],
          },
        },
        function (err) {
          if (err) {
            console.error(err);
          }
        },
      );
  }

  i18next.on('missingKey', function (lngs, namespace, key, res) {
    // eslint-disable-next-line no-console
    console.warn(lngs, namespace, key, res);
  });
};

const get = (
  key: string,
  defaultValue = '',
  replacements: string[] = [],
): string => {
  let value: string;

  if (i18next.exists(key)) {
    value = i18next.t(key);
  } else {
    value = i18next.t(key, { defaultValue, ns: 'common' });
  }

  if (value && replacements.length) {
    const groups = value.match(INTERPOLATION_REGEX);
    if (groups) {
      groups.forEach((group, index) => {
        value = value.replace(group, replacements[index]);
      });
    }
  }

  return value;
};

const getArray = (key: string, defaultValue: string[] = []): string[] => {
  let value: any;

  if (i18next.exists(key)) {
    // Intenta obtener la propiedad 'items' del objeto
    const itemsArray = i18next.t(`${key}.items`, { returnObjects: true }) || [];

    if (Array.isArray(itemsArray)) {
      // Si la propiedad 'items' es un array, lo retornamos
      //@ts-ignore
      return itemsArray;
    } else {
      // Si no es un array, intentamos obtener el valor directamente
      value = itemsArray;
    }
  } else {
    value = i18next.t(key, { defaultValue, ns: 'common' });
  }

  // Si el valor es un array, lo retornamos
  if (Array.isArray(value)) {
    return value;
  }

  // Si no es un array, retornamos el valor por defecto o un array vacío
  return value || defaultValue;
};

export default { init, get, getArray };
