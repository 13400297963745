import './FieldContent.scss';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { Field, FieldTypes } from '../types/form';
import {
  Grid,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Radio,
  RadioGroup,
  Chip,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import DateAdapter from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import TimePicker from '@mui/lab/TimePicker';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { IAppContext, SelectedValues } from '../types';
import { setFormFieldValue } from '../helpers/Utils';
import { AppContext } from '../context/Context';
import dayjs from 'dayjs';
import {
  getPreInspection,
  savePreInspection,
} from '../services/preInspect.service';
import Signature from '../components/common/Signature';
import CheckBoxData from '../components/common/CheckboxData';
import localforage from 'localforage';
import { ActionType } from '../context/actions';

interface FieldProps {
  field: Field;
  classes?: Record<string, any>;
  isDisabled?: boolean;
  countryId?: number;
  options?: any;
  triggerValidation?: () => { isForm: boolean; result: any };
}

const FieldContent = ({
  field,
  isDisabled,
  triggerValidation,
}: FieldProps): JSX.Element => {
  const { state, dispatch } = useContext(AppContext) as IAppContext;
  const defaultValue =
    field.fieldFormValue && field.fieldFormValue !== 'UNDEFINED'
      ? field.fieldFormValue
      : field.defaultValue;

  field = {
    ...field,
    labelName:
      field.required === true ? `${field.labelName} *` : field.labelName,
  };

  const activeBlur = (): void => {
    (async (): Promise<void> => {
      const request = triggerValidation
        ? triggerValidation()
        : {
            result: { valid: true },
          };

      if (request.result && request.result.valid) {
        await savePreInspection(state.formData, state, dispatch);
        setTimeout(async () => {
          const currentId: any = await localforage.getItem('preId');
          const objData = await getPreInspection(currentId);
          dispatch({
            type: ActionType.SET_PREINSPECTION_MODEL,
            payload: objData,
          });
        }, 1000);
      }
    })();
  };

  const activeOnChange = (event: any, fieldType?: string): void => {
    event.target.value = regexValidation(event.target.value, fieldType);

    setFormFieldValue(
      event.target,
      dispatch,
      state.preInspectionModel,
      state.formData,
    );

    if (triggerValidation) {
      triggerValidation();
    }
  };

  if (field.visible) {
    switch (field.fieldFormType?.toLowerCase()) {
      case FieldTypes.CHECKBOX: {
        field.options = field.checkBoxOptions?.split('¬');
        const sOptions = field.options ?? [];

        return (
          <>
            {sOptions.map((option: string) => (
              <CheckBoxData
                key={option}
                label={option}
                defaultValue={defaultValue}
                field={field}
                isDisabled={isDisabled}
                onBlur={activeBlur}
                onChange={(e) => activeOnChange(e)}
              />
            ))}
          </>
        );
      }
      case FieldTypes.RADIOBUTTON: {
        field.options = field.radioButtonOptions?.split('¬');
        const sOptions = field.options ?? [];
        const optValues: SelectedValues[] = [];
        sOptions.map((option: string) => {
          const newOption = getSelectedValues(option);
          optValues.push(newOption);

          return true;
        });

        return (
          <>
            <InputLabel key={field.name}>{field.labelName}</InputLabel>
            <RadioGroup
              aria-label={field.labelName}
              name={field.labelName}
              value={defaultValue}
              onChange={(e) => activeOnChange(e)}
              //onBlur={activeBlur}
              row
            >
              {optValues.map((option: SelectedValues) => (
                <FormControlLabel
                  className={field.hasError ? 'rdError' : ''}
                  key={option.label}
                  value={option.value}
                  control={
                    <Radio
                      name={`${field.name}|${field.formSectionId}`}
                      disabled={isDisabled}
                    />
                  }
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </>
        );
      }
      case FieldTypes.STYLIZED_RADIOBUTTONS: {
        field.options = field.radioButtonOptions?.split('¬');
        const sOptions = field.options ?? [];
        const optionValues: SelectedValues[] = sOptions.map((option: string) =>
          getSelectedValues(option),
        );
        const getSelectedClass = (value: any) =>
          defaultValue === value
            ? 'preInspection__toggle-button--selected'
            : '';

        const getOptionsValues = () =>
          optionValues.map((option: SelectedValues) => (
            <Button
              key={option.value}
              name={`${field.idName}|${field.formSectionId}`}
              value={option.value}
              variant="outlined"
              disableElevation
              className={clsx(
                field.hasError
                  ? 'preInspection__toggle-button-error'
                  : 'preInspection__toggle-button',
                getSelectedClass(option.value),
              )}
              onBlur={activeBlur}
              disabled={isDisabled}
            >
              {option.label}
            </Button>
          ));

        return (
          <div className={clsx('preInspection__sradio')}>
            <InputLabel key={field.idName}>{field.labelName}</InputLabel>
            <Grid container justifyContent="flex-start" direction="row">
              {getOptionsValues()}
            </Grid>
          </div>
        );
      }
      case FieldTypes.DROPDOWN:
      case FieldTypes.MULTIDROPDOWN: {
        const isMultiDropdown = field.fieldFormType === FieldTypes.MULTIDROPDOWN.toUpperCase();
        field.options = field.dropDownOptions?.split('¬');
        let selectValues: SelectedValues[] = [];
        const sOptions = field.options ?? [];
        selectValues = getOptionsByTemplate(sOptions);
        const checkDefaultValue = isMultiDropdown  ? (defaultValue ? (defaultValue as string).split(',') : [] ): castSelectedvalues(defaultValue);
       
        return (
          <FormControl
        variant="outlined"
        className={clsx('preInspection__input')}
          >
        <InputLabel
          key={field.idName}
          style={{
            // Fixes select label drawn with input border
            backgroundColor: 'white',
            paddingRight: 5,
            paddingLeft: 5,
          }}
        >
          {field.labelName}
        </InputLabel>
        <Select
          key={field.formSectionId}
          onChange={(e) => activeOnChange(e)}
          defaultValue={checkDefaultValue ?? ''}
          value={checkDefaultValue || []}
          onBlur={activeBlur}
          name={`${field.idName}|${field.formSectionId}`}
          disabled={isDisabled}
          error={field.hasError ? true : false}
          multiple={isMultiDropdown}
          displayEmpty
          renderValue={
            checkDefaultValue == null || checkDefaultValue.length === 0
              ? () => 'SELECCIONE'
              : undefined
          }
        >
          {selectValues.map((optionVal: SelectedValues) => (
            <MenuItem key={optionVal.label} value={optionVal.value}>
          {optionVal.label.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
          </FormControl>
        );
      }
      case FieldTypes.DYNAMIC_DROPDOWN: {
        const selectDynamicValues: SelectedValues[] = [];
        const map = new Map();
        if (field.options) {
          field.options.forEach((option: string) => {
            const newOption = getSelectedValues(option);
            if (!map.has(newOption.value)) {
              map.set(newOption.value, true);
              selectDynamicValues.push(newOption);
            }
          });
        }

        return (
          <FormControl
            variant="outlined"
            className={clsx('preInspection__input')}
          >
            <InputLabel
              key={field.idName}
              style={{
                // Fixes select label drawn with input border
                backgroundColor: 'white',
                paddingRight: 5,
                paddingLeft: 5,
              }}
            >
              {field.labelName}
            </InputLabel>
            <Select
              key={`${field.name}-${field.value}`}
              onChange={(e) => activeOnChange(e)}
              defaultValue={defaultValue ?? ''}
              onBlur={activeBlur}
              name={`${field.name}|${field.formSectionId}`}
              disabled={isDisabled}
              error={field.hasError ? true : false}
            >
              {selectDynamicValues.map((option: SelectedValues) => (
                <MenuItem key={option.label} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }
      case FieldTypes.DISCLAIMER:
        return <p>{field.contentText}</p>;
      case FieldTypes.BUTTON:
        return (
          <Button
            key="btnEdit"
            name="btnEdit"
            variant="outlined"
            className={clsx('settingsForm__toggle-button')}
          >
            {field.labelName}
          </Button>
        );
      case FieldTypes.HTMLTEXT: {
        const letterText = String(field.contentText);

        return (
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: letterText }}
          ></div>
        );
      }
      case FieldTypes.TEXTAREA:
        return (
          <TextField
            key={field.name}
            className={clsx('preInspection__inputArea')}
            name={`${field.idName}|${field.formSectionId}`}
            label={field.labelName}
            defaultValue={defaultValue ?? ''}
            variant="outlined"
            rows={3}
            multiline={true}
            onChange={(e) => activeOnChange(e)}
            onBlur={activeBlur}
            disabled={isDisabled}
            error={field.hasError ? true : false}
          />
        );
      case FieldTypes.DATE: {
        const date =
          defaultValue && defaultValue.split('T')[0]
            ? new Date(defaultValue.split('T')[0] + ' 00:00:00')
            : null;
        const validDate = date instanceof Date && !isNaN(date.getTime());
        const dateValue = validDate && date ? date : null;

        return (
          <LocalizationProvider dateAdapter={DateAdapter}>
            <Stack spacing={3}>
              <MobileDatePicker
                label={field.labelName}
                inputFormat="MM/DD/YYYY"
                value={dateValue}
                onChange={(date) => {
                  const event = {
                    target: {
                      name: `${field.idName}|${field.formSectionId}`,
                      value: dayjs(date).format('YYYY-MM-DD'),
                    },
                  };
                  activeOnChange(event);
                }}
                renderInput={(params) => {
                  params.name = `${field.idName}|${field.formSectionId}`;
                  params.onBlur = () => {
                    activeBlur();
                  };

                  return <TextField {...params} />;
                }}
              />
            </Stack>
          </LocalizationProvider>
        );
      }
      case FieldTypes.TIME: {
        const [hour, mins] = defaultValue
          ?.replace(/AM|PM|\s/gi, '')
          ?.split(':') || ['00', '00'];

        const value =
          hour && mins ? new Date().setHours(Number(hour), Number(mins)) : null;

        return (
          <LocalizationProvider dateAdapter={DateAdapter}>
            <Stack spacing={3}>
              <TimePicker
                label={field.labelName}
                value={value}
                onChange={() => {
                  // eslint-disable-next-line no-console
                  console.log('timer picker');
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider>
        );
      }
      case FieldTypes.NUMBER:
        return (
          <TextField
            key={field.name}
            className={clsx('preInspection__input')}
            name={`${field.idName}|${field.formSectionId}`}
            label={field.labelName}
            defaultValue={defaultValue ?? ''}
            variant="outlined"
            onChange={(e) => activeOnChange(e)}
            onBlur={activeBlur}
            type="number"
            disabled={isDisabled}
            error={field.hasError ? true : false}
          />
        );
      case FieldTypes.MAP:
        return (
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${
              defaultValue || '18.449815,-66.073410'
            }&zoom=${20}&key=${String(
              process.env.REACT_APP_GMAPS_KEY,
            )}&size=600x400`}
            alt="map location"
          />
        );
      case FieldTypes.FILLED_TEXT:
        return (
          <TextField
            key={field.name}
            className={clsx('preInspection__input')}
            name={`${field.idName}|${field.formSectionId}`}
            label={field.labelName}
            defaultValue={defaultValue ?? ''}
            variant="outlined"
            size="small"
            onChange={(e) => activeOnChange(e)}
            onBlur={activeBlur}
            disabled={isDisabled}
            error={field.hasError ? true : false}
          />
        );
      case FieldTypes.CHIPSTATUS:
        return (
          <>
            <div className="preInspection__chipStatus MuiGrid-root MuiGrid-container">
              <div className="MuiGrid-root MuiGrid-item chipLabel">
                <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated">
                  {field.labelName}
                </label>
              </div>
              <Chip
                className={clsx('chipTop')}
                icon={
                  defaultValue === 'Validada' ? (
                    <DoneIcon />
                  ) : (
                    <ErrorOutlineIcon />
                  )
                }
                label={defaultValue ?? ''}
                clickable
                color={defaultValue === 'Validada' ? 'primary' : 'default'}
              />
            </div>
          </>
        );
      case FieldTypes.SIGNATURE:
        return (
          <Signature
            signature={defaultValue}
            onSignatureChange={(signature) => {
              const event = {
                target: {
                  name: `${field.idName}|${field.formSectionId}`,
                  value: signature,
                },
              };
              activeOnChange(event);
            }}
            onConfirm={activeBlur}
            hasError={field.hasError}
            isDisabled={field.isDisabled}
          />
        );
      case FieldTypes.TEXT:
      default:
        return (
          <>
            {field.endPoint &&
            (field.endPoint.toUpperCase() === 'INTEGER' ||
              field.endPoint.toUpperCase() === 'INTEGERSTR' ||
              field.endPoint.toUpperCase() === 'DECIMAL') ? (
              <TextField
                key={field.name}
                name={`${field.idName}|${field.formSectionId}`}
                label={field.labelName}
                type="number"
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
                defaultValue={defaultValue ?? ''}
                variant="outlined"
                onChange={(e) => activeOnChange(e)}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (field.modelFieldName === 'PhoneNumber') {
                    // Here a solution to limitate the number of characters, because max property doesn't work and maxLength property is for type='text'
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }
                }}
                onBlur={activeBlur}
                disabled={isDisabled}
                error={field.hasError ? true : false}
              />
            ) : (
              <TextField
                key={field.name}
                name={`${field.idName}|${field.formSectionId}`}
                label={field.labelName}
                defaultValue={defaultValue ?? ''}
                variant="outlined"
                placeholder={
                  field.endPoint && field.endPoint.toUpperCase() === 'DATETEXT'
                    ? 'Ejemplo 28/10/2020'
                    : ''
                }
                onChange={(e) => activeOnChange(e, field.endPoint)}
                onBlur={activeBlur}
                disabled={isDisabled}
                error={field.hasError ? true : false}
                inputProps={
                  field.maxLength && field.maxLength > 0
                    ? {
                        maxLength: field.maxLength,
                      }
                    : {}
                }
              />
            )}
          </>
        );
    }
  } else {
    return <></>;
  }
};

export default FieldContent;

const getSelectedValues = (option: string) => {
  const values = option.split('|');
  const selectedVal: SelectedValues = {
    label: values[1],
    value: values[0],
  };

  return selectedVal;
};

const getOptionsByTemplate = (options: any[]): SelectedValues[] => {
  const currentValues = Array.from(options);
  const resultValues: SelectedValues[] = [];

  currentValues.forEach((option: string) => {
    const newOption = getSelectedValues(option);
    resultValues.push(newOption);
  });

  return resultValues;
};

const castSelectedvalues = (option: string): string => {
  if (option && option.length > 0 && option.toUpperCase().includes('CEDULA')) {
    return 'CC';
  }

  return option;
};

const regexValidation = (currentValue: any, fieldType?: string): string => {
  // Special characters validation
  //eslint-disable-next-line
  let specialChars =
    '¿!@#"$^&%*()+=/[]{}|:<>?,.×÷_--;`~€£¥₩♧◇♡♤■□●○•°☆▪︎¤《¡》';

  if (fieldType && fieldType === 'ONLYLETTERS') {
    const noNumbers = currentValue.replace(/[0-9]/g, '');
    currentValue = noNumbers;
  }
  if (fieldType && fieldType === 'PERSONALID') {
    //eslint-disable-next-line
    specialChars = '¿@!#"$^&%*()+=/[]{}|:<>?,×÷;`~€£¥₩♧◇♡♤■□●○•°☆▪︎¤《¡》';
  }

  if (fieldType && fieldType === 'EMAIL') {
    //eslint-disable-next-line
    specialChars = '¿!#"$^&%*()+=/[]{}|:<>?,×÷;`~€£¥₩♧◇♡♤■□●○•°☆▪︎¤《¡》';
  }

  if (fieldType !== undefined) {
    for (let i = 0; i < specialChars.length; i++) {
      currentValue = currentValue.replace(
        new RegExp('\\' + specialChars[i], 'gi'),
        '',
      );
    }

    return currentValue
      .replace(/["']/g, '')
      .replace(String.fromCharCode(92), '');
  }

  return currentValue;
};
