import { useEffect, useRef, FunctionComponent } from 'react';
import lottie from 'lottie-web';

interface AnimationProps {
  path: any;
}

const Animation: FunctionComponent<AnimationProps> = ({ path }) => {
  const container = useRef(null);

  useEffect(() => {
    if (container.current) {
      lottie.loadAnimation({
        container: container.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: path,
      });
    }
  }, []);

  return <div className="container" ref={container}></div>;
};

export default Animation;
