import Animation from '../Animation';
import TimeAnimation from '../../animations/timed.json';
import TimeAnimationSura from '../../animations/timed_bcr.json';
import TimeAnimationBcr from '../../animations/timed_bcr.json';
import TimeAnimationConnect from '../../animations/timed_connect.json';
import CameraAnimation from '../../animations/camera.json';
import CameraAnimationSura from '../../animations/camera_bcr.json';
import CameraAnimationBcr from '../../animations/camera_bcr.json';
import CameraAnimationConnect from '../../animations/camera_connect.json';
import LocalizationAnimation from '../../animations/location.json';
import LocalizationAnimationSura from '../../animations/location_bcr.json';
import LocalizationAnimationBcr from '../../animations/location_bcr.json';
import LocalizationAnimationConnect from '../../animations/location_connect.json';
import DocumentAnimation from '../../animations/document.json';
import DocumentAnimationConnect from '../../animations/document_connect.json';
import './Pages.scss';
import { getPageIndex } from '../../helpers/Utils';
import { BRAND } from '../../static/constants/brands/brand';
import { Company } from '../../interfaces/brand';
import i18n from '../../utils/i18n';

const brandStr = BRAND;

export const Pages = (): any[] => {
  return [
    <div className="page" key={getPageIndex(brandStr, 'documents')}>
      <div className="circle">
        <div className="image-container">
          <Animation
            path={
              brandStr === Company.CONNECT
                ? DocumentAnimationConnect
                : DocumentAnimation
            }
          />
        </div>
      </div>
      <div className="text-container">
        <h2>{i18n.get('onboarding.documents.title', 'Documentos')}</h2>
        <div id="circle"></div>
        <p>
          <b>
            {i18n.get(
              'onboarding.documents.subtitle',
              'Prepara los siguientes documentos para el proceso',
            )}
          </b>
        </p>
        <ul>
          {i18n
            .getArray('onboarding.documents.list')
            .map((item: any, index: number) => (
              <li key={index}>{item}</li>
            ))}
        </ul>
      </div>
    </div>,
    <div className="page" key={getPageIndex(brandStr, 'time')}>
      <div className="circle">
        <div className="image-container">
          <Animation
            path={
              brandStr === Company.BCR
                ? TimeAnimationBcr
                : brandStr === Company.CONNECT
                ? TimeAnimationConnect
                : brandStr === Company.SURA
                ? TimeAnimationSura
                : TimeAnimation
            }
          />
        </div>
      </div>
      <div className="text-container">
        <h2>{i18n.get('onboarding.time.title', 'Tiempo')}</h2>
        <p>
          {i18n.get(
            'onboarding.time.label',
            'Ten presente que este es un proceso grabado en tiempo real, fechado y cronometrado.',
          )}
        </p>
        <div className="body-info-text">
          <div
            dangerouslySetInnerHTML={{
              __html: i18n.get('onboarding.time.description', ''),
            }}
          ></div>
        </div>
      </div>
    </div>,
    <div className="page" key={getPageIndex(brandStr, 'photos')}>
      <div className="circle">
        <div className="image-container">
          <Animation
            path={
              brandStr === Company.BCR
                ? CameraAnimationBcr
                : brandStr === Company.CONNECT
                ? CameraAnimationConnect
                : brandStr === Company.SURA
                ? CameraAnimationSura
                : CameraAnimation
            }
          />
        </div>
      </div>
      <div className="text-container">
        <h2>{i18n.get('onboarding.photos.title', 'Fotografías')}</h2>
        <p>
          <b>
            {i18n.get(
              'onboarding.photos.label',
              'Toma en cuenta lo siguiente:',
            )}
          </b>
        </p>
        <div className="photo-intro-description">
          <ul>
            {i18n
              .getArray('onboarding.photos.list')
              .map((item: any, index: number) => (
                <li key={index}>{item}</li>
              ))}
          </ul>
        </div>
      </div>
    </div>,
    <div className="page" key={getPageIndex(brandStr, 'location')}>
      <div className="circle">
        <div className="image-container">
          <Animation
            path={
              brandStr === Company.BCR
                ? LocalizationAnimationBcr
                : brandStr === Company.CONNECT
                ? LocalizationAnimationConnect
                : brandStr === Company.SURA
                ? LocalizationAnimationSura
                : LocalizationAnimation
            }
          />
        </div>
      </div>
      <div className="text-container">
        <h2>{i18n.get('onboarding.location.title', 'Localización')}</h2>
        <p>
          {i18n.get(
            'onboarding.location.label',
            'Para continuar con el proceso en línea',
          )}
        </p>
      </div>
    </div>,
  ];
};
