import localforage from 'localforage';
import { FC, useContext, useEffect, useState } from 'react';
import ContinueButton from '../components/carousel/ContinueButton';
import './WelcomeScreen.scss';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { AppContext } from '../context/Context';
import { IAppContext } from '../types';
import PATH_ROUTES from '../static/constants/path-routes';
import { BRAND, WelcomeImageByBrand } from '../static/constants/brands/brand';
import i18n from '../utils/i18n';
import { Company } from '../interfaces/brand';
import { ActionType } from '../context/actions';

const WelcomeScreen: FC = () => {
  const history = useHistory();
  const {
    state: {
      preInspectionModel: { preInspectionId, preActiveQuestionBroker },
    },
    dispatch,
  } = useContext(AppContext) as IAppContext;
  const [isBroker, setIsBroker] = useState(false);

  const handleContinue = async () => {
    await localforage.setItem('acceptConditions', true);
    if (preInspectionId > 0 && BRAND !== Company.BCR) {
      history.push(PATH_ROUTES.PRE_INFO_SCREEN);
    } else {
      history.push(PATH_ROUTES.PRE_ONBOARDING_SCREEN);
    }
  };

  const handleDialogButtonClose = () => {
    dispatch({
      type: ActionType.SET_IS_BROKER,
      payload: false,
    });
    setIsBroker(false);
  };

  const handleDialogButtonContinue = () => {
    dispatch({
      type: ActionType.SET_IS_BROKER,
      payload: true,
    });
    setIsBroker(false);
  };

  const handleDialogClose = (
    event: any,
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (reason === 'backdropClick') {
      console.log(reason);
    } else {
      setIsBroker(false);
    }
  };

  const handleBackdropClick = (event: any) => {
    //these fail to keep the modal open
    event.stopPropagation();

    return false;
  };

  useEffect(() => {
    if (preActiveQuestionBroker === true) {
      setIsBroker(true);
    }
  }, []);

  return (
    <>
      <div className="welcome">
        <div className="welcome__intro">
          <h1>{i18n.get('welcome.title', 'Hola')}</h1>
          <p
            dangerouslySetInnerHTML={{
              __html: i18n.get(
                'welcome.sub_title',
                'Toma en cuenta que el proceso...',
              ),
            }}
          ></p>
          <img src={WelcomeImageByBrand[BRAND]} />
        </div>
        <ContinueButton onClick={handleContinue}>
          Acepto y continuar
        </ContinueButton>
      </div>
      <Dialog
        open={isBroker}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onBackdropClick={handleBackdropClick}
      >
        <DialogTitle id="alert-dialog-title">
          {'Confirmación de Corredor'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Es usted el corredor?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="btnWelcomeDialog"
            onClick={handleDialogButtonClose}
            autoFocus
          >
            No
          </Button>
          <Button
            className="btnWelcomeDialog"
            onClick={handleDialogButtonContinue}
          >
            Soy Corredor
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WelcomeScreen;
