import { FC, useContext } from 'react';
import { BRAND, LOGOS } from '../../static/constants/brands/brand';
import './Layout.scss';
import noImage from '../../assets/imgs/no-image.png';
import { AppContext } from '../../context/Context';
import { IAppContext } from '../../types';
import { Company } from '../../interfaces/brand';
const Layout: FC = ({ children }) => {
  const {
    state: { finishStatus },
  } = useContext(AppContext) as IAppContext;

  const renderHeaderLayout = () => {
    if (BRAND !== Company.BCR) {
      return true;
    }
    if (finishStatus === null) {
      return true;
    }

    return false;
  };

  return (
    <div className="layout">
      {renderHeaderLayout() ? (
        <header className="layout__header">
          <picture id="brand-logo">
            <source srcSet={LOGOS[BRAND]} />
            <img src={noImage} alt="Application Logo" />
          </picture>
        </header>
      ) : (
        ''
      )}
      <main className="layout__main">{children}</main>
    </div>
  );
};

export default Layout;
