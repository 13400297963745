import { FunctionComponent, useContext, useState } from 'react';
import ContinueButton from './carousel/ContinueButton';
import { AppContext } from '../context/Context';
import { IAppContext } from '../types';
import { savePreInspection } from '../services/preInspect.service';
import localforage from 'localforage';
import { Alert } from '@mui/material';
import '../screens/LocalizationScreen.scss';

const LocationReject: FunctionComponent = () => {
  const { state, dispatch } = useContext(AppContext) as IAppContext;
  const [showMessage, setShowMessage] = useState(false);

  const handleOperatingSystem = async () => {
    // Determinar el sistema operativo para mostrar el link
    const isiOS =
      !!navigator.userAgent && /ipad|ipod|iphone/i.test(navigator.userAgent);
    if (isiOS) {
      // Redireccionar en caso q sea IOS
      window.location.replace('https://support.apple.com/es-es/HT207092');
    } else {
      // Redireccionar en caso q sea android
      window.location.replace(
        'https://support.google.com/chrome/answer/142065?hl=es&co=GENIE.Platform%3DAndroid',
      );
    }
  };

  const handleSaveClick = async () => {
    await localforage.setItem('indOffice', true);
    await savePreInspection(state.formData, state, dispatch);
    setShowMessage(true);
  };

  return (
    <>
      {showMessage && (
        <Alert severity="info">La información ha sido enviada</Alert>
      )}
      <div className="welcome">
        <div className="welcome__intro">
          <h2>Permiso no brindado</h2>
          <p>
            Lamentablemente no hemos podido obtener tu ubicación actual. Para
            continuar con el proceso, es necesario que concedas los permisos de
            localización.{' '}
          </p>

          <p>
            Por favor, selecciona una de las siguientes opciones para brindarte
            una mejor atención y continuar con tu solicitud.
          </p>
          <ContinueButton onClick={handleOperatingSystem}>
            ¿Cómo compartir mi ubicación?
          </ContinueButton>
          <ContinueButton onClick={handleSaveClick}>
            Estaré acudiendo a una oficina
          </ContinueButton>
        </div>
      </div>
    </>
  );
};

export default LocationReject;
