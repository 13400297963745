export const BRAND = process.env.REACT_APP_BRAND || 'connect';

import defaultImg from '../../../assets/imgs/no-image.png';

import isLogo from '../../../assets/imgs/brand-logos/is_logo.svg';
import connectLogo from '../../../assets/imgs/brand-logos/connect_logo.svg';
import suraLogo from '../../../assets/imgs/brand-logos/sura_logo.svg';
import bcrLogo from '../../../assets/imgs/brand-logos/logo_bcrcr.svg';

import IsWelcomeImg from '../../../assets/imgs/il_proceso.svg';
import SuraWelcomeImg from '../../../assets/imgs/il_proceso_sura.svg';
import BcrWelcomeImg from '../../../assets/imgs/il_proceso_bcr.svg';
import ConnectWelcomeImg from '../../../assets/imgs/il_proceso_connect.svg';

import IsExteriorIcon from '../../../assets/imgs/photo-menu/i-exterior.svg';
import SuraExteriorIcon from '../../../assets/imgs/photo-menu/i-exterior-sura.svg';
import BcrExteriorIcon from '../../../assets/imgs/photo-menu/i-exterior - bcr.svg';
import ConnectExteriorIcon from '../../../assets/imgs/photo-menu/i-exterior-connect.svg';

import IsInteriorIcon from '../../../assets/imgs/photo-menu/i-interior.svg';
import SuraInteriorIcon from '../../../assets/imgs/photo-menu/i-interior-sura.svg';
import BcrInteriorIcon from '../../../assets/imgs/photo-menu/i-interior - bcr.svg';
import ConnectInteriorIcon from '../../../assets/imgs/photo-menu/i-interior-connect.svg';

import IsDocumentIcon from '../../../assets/imgs/photo-menu/i-documento.svg';
import SuraDocumentIcon from '../../../assets/imgs/photo-menu/i-documento-sura.svg';
import BcrDocumentIcon from '../../../assets/imgs/photo-menu/i-documento - bcr.svg';
import ConnectDocumentIcon from '../../../assets/imgs/photo-menu/i-documento-connect.svg';

import IsVideoIcon from '../../../assets/imgs/photo-menu/i-360video.svg';
import SuraVideoIcon from '../../../assets/imgs/photo-menu/i-360video-sura.svg';
import BcrVideoIcon from '../../../assets/imgs/photo-menu/i-360video - bcr.svg';
import ConnectVideoIcon from '../../../assets/imgs/photo-menu/i-360video-connect.svg';

import IsPlateIcon from '../../../assets/imgs/photo-placeholders/exterior/plate.svg';
import SuraPlateIcon from '../../../assets/imgs/photo-placeholders/exterior/plate_sura.svg';
import BcrPlateIcon from '../../../assets/imgs/photo-placeholders/exterior/plate_bcr.svg';
import ConnectPlateIcon from '../../../assets/imgs/photo-placeholders/exterior/plate_connect.svg';

import IsFrontIcon from '../../../assets/imgs/photo-placeholders/exterior/front.svg';
import SuraFrontIcon from '../../../assets/imgs/photo-placeholders/exterior/front_sura.svg';
import BcrFontIcon from '../../../assets/imgs/photo-placeholders/exterior/front_bcr.svg';
import ConnectFrontIcon from '../../../assets/imgs/photo-placeholders/exterior/front_connect.svg';

import IsLeftIcon from '../../../assets/imgs/photo-placeholders/exterior/left.svg';
import SuraLeftIcon from '../../../assets/imgs/photo-placeholders/exterior/left_sura.svg';
import BcrLeftIcon from '../../../assets/imgs/photo-placeholders/exterior/left_bcr.svg';
import ConnectLeftIcon from '../../../assets/imgs/photo-placeholders/exterior/left_connect.svg';

import IsRightIcon from '../../../assets/imgs/photo-placeholders/exterior/right.svg';
import SuraRightIcon from '../../../assets/imgs/photo-placeholders/exterior/right_sura.svg';
import BcrRightIcon from '../../../assets/imgs/photo-placeholders/exterior/right_bcr.svg';
import ConnectRightIcon from '../../../assets/imgs/photo-placeholders/exterior/right_connect.svg';

import IsBackIcon from '../../../assets/imgs/photo-placeholders/exterior/back.svg';
import SuraBackIcon from '../../../assets/imgs/photo-placeholders/exterior/back_sura.svg';
import BcrBackIcon from '../../../assets/imgs/photo-placeholders/exterior/back_bcr.svg';
import ConnectBackIcon from '../../../assets/imgs/photo-placeholders/exterior/back_connect.svg';

import IsScanIcon from '../../../assets/imgs/photo-placeholders/documents/i-scan.svg';
import SuraScanIcon from '../../../assets/imgs/photo-placeholders/documents/i-scan_sura.svg';
import BcrScanIcon from '../../../assets/imgs/photo-placeholders/documents/i-scan_bcr.svg';
import ConnectScanIcon from '../../../assets/imgs/photo-placeholders/documents/i-scan_connect.svg';

import IsInteriorPlaceIcon from '../../../assets/imgs/photo-placeholders/interior/i-interior-1.svg';
import SuraInteriorPlaceIcon from '../../../assets/imgs/photo-placeholders/interior/i-interior-1_sura.svg';
import BcrInteriorPlaceIcon from '../../../assets/imgs/photo-placeholders/interior/i-interior-1_bcr.svg';
import ConnectInteriorPlaceIcon from '../../../assets/imgs/photo-placeholders/interior/i-interior-1_connect.svg';

import IsVinIcon from '../../../assets/imgs/photo-placeholders/interior/i-vin.svg';
import SuraVinIcon from '../../../assets/imgs/photo-placeholders/interior/i-vin_sura.svg';
import BcrVinIcon from '../../../assets/imgs/photo-placeholders/interior/i-vin_bcr.svg';
import ConnectVinIcon from '../../../assets/imgs/photo-placeholders/interior/i-vin_connect.svg';

import IsSpeedometerIcon from '../../../assets/imgs/photo-placeholders/interior/i-speedometer.svg';
import SuraSpeedometerIcon from '../../../assets/imgs/photo-placeholders/interior/i-speedometer_sura.svg';
import BcrSpeedometerIcon from '../../../assets/imgs/photo-placeholders/interior/i-speedometer_bcr.svg';
import ConnectSpeedometerIcon from '../../../assets/imgs/photo-placeholders/interior/i-speedometer_connect.svg';

import IsDekraIcon from '../../../assets/imgs/photo-placeholders/documents/i-scan.svg';
import BcrDekraIcon from '../../../assets/imgs/photo-placeholders/documents/i-scan_bcr.svg';
import ConnectDekraIcon from '../../../assets/imgs/photo-placeholders/documents/i-scan_connect.svg';

import IsTrunkIcon from '../../../assets/imgs/photo-placeholders/documents/i-scan.svg';
import SuraTrunkIcon from '../../../assets/imgs/photo-placeholders/documents/i-scan_sura.svg';
import BcrTrunkIcon from '../../../assets/imgs/photo-placeholders/documents/i-scan_bcr.svg';
import ConnectTrunkIcon from '../../../assets/imgs/photo-placeholders/documents/i-scan_connect.svg';

import IsfinishIcon from '../../../assets/imgs/i-finalizado.svg';
import SuraFinishIcon from '../../../assets/imgs/i-finalizado_sura.svg';
import BcrFinishIcon from '../../../assets/imgs/i-finalizado_bcr.svg';
import ConnectFinishIcon from '../../../assets/imgs/i-finalizado_connect.svg';

import IsCompleteImg from '../../../assets/imgs/complete.svg';
import SuraCompleteImg from '../../../assets/imgs/complete_sura.svg';
import ConnectCompleteImg from '../../../assets/imgs/complete_connect.svg';

import BcrUploadIcon from '../../../assets/imgs/upload-icon-bcr.svg';

export const LOGOS: { [key: string]: string } = {
  is: isLogo,
  connect: connectLogo,
  sura: suraLogo,
  bcr: bcrLogo,
};

export const WelcomeImageByBrand: { [key: string]: string } = {
  is: IsWelcomeImg,
  connect: ConnectWelcomeImg,
  sura: SuraWelcomeImg,
  bcr: BcrWelcomeImg,
};

export const exteriorIcon: { [key: string]: string } = {
  is: IsExteriorIcon,
  connect: ConnectExteriorIcon,
  sura: SuraExteriorIcon,
  bcr: BcrExteriorIcon,
};

export const interiorIcon: { [key: string]: string } = {
  is: IsInteriorIcon,
  connect: ConnectInteriorIcon,
  sura: SuraInteriorIcon,
  bcr: BcrInteriorIcon,
};

export const documentIcon: { [key: string]: string } = {
  is: IsDocumentIcon,
  connect: ConnectDocumentIcon,
  sura: SuraDocumentIcon,
  bcr: BcrDocumentIcon,
};

export const video360Icon: { [key: string]: string } = {
  is: IsVideoIcon,
  connect: ConnectVideoIcon,
  sura: SuraVideoIcon,
  bcr: BcrVideoIcon,
};

export const plateIcon: { [key: string]: string } = {
  is: IsPlateIcon,
  connect: ConnectPlateIcon,
  sura: SuraPlateIcon,
  bcr: BcrPlateIcon,
};

export const frontSideIcon: { [key: string]: string } = {
  is: IsFrontIcon,
  connect: ConnectFrontIcon,
  sura: SuraFrontIcon,
  bcr: BcrFontIcon,
};

export const leftSideIcon: { [key: string]: string } = {
  is: IsLeftIcon,
  connect: ConnectLeftIcon,
  sura: SuraLeftIcon,
  bcr: BcrLeftIcon,
};

export const rightSideIcon: { [key: string]: string } = {
  is: IsRightIcon,
  connect: ConnectRightIcon,
  sura: SuraRightIcon,
  bcr: BcrRightIcon,
};

export const backSideIcon: { [key: string]: string } = {
  is: IsBackIcon,
  connect: ConnectBackIcon,
  sura: SuraBackIcon,
  bcr: BcrBackIcon,
};

export const scanIcon: { [key: string]: string } = {
  is: IsScanIcon,
  connect: ConnectScanIcon,
  sura: SuraScanIcon,
  bcr: BcrScanIcon,
};

export const interiorPlaceIcon: { [key: string]: string } = {
  is: IsInteriorPlaceIcon,
  connect: ConnectInteriorPlaceIcon,
  sura: SuraInteriorPlaceIcon,
  bcr: BcrInteriorPlaceIcon,
};

export const vinIcon: { [key: string]: string } = {
  is: IsVinIcon,
  connect: ConnectVinIcon,
  sura: SuraVinIcon,
  bcr: BcrVinIcon,
};

export const speedometerIcon: { [key: string]: string } = {
  is: IsSpeedometerIcon,
  connect: ConnectSpeedometerIcon,
  sura: SuraSpeedometerIcon,
  bcr: BcrSpeedometerIcon,
};

export const dekraIcon: { [key: string]: string } = {
  is: IsDekraIcon,
  connect: ConnectDekraIcon,
  sura: defaultImg,
  bcr: BcrDekraIcon,
};

export const trunkIcon: { [key: string]: string } = {
  is: IsTrunkIcon,
  connect: ConnectTrunkIcon,
  sura: SuraTrunkIcon,
  bcr: BcrTrunkIcon,
};

export const finishIcon: { [key: string]: string } = {
  is: IsfinishIcon,
  connect: ConnectFinishIcon,
  sura: SuraFinishIcon,
  bcr: BcrFinishIcon,
};

export const uploadIcon: { [key: string]: string } = { 
  bcr: BcrUploadIcon,
};

export const completeImg: {
  [key: string]: { source: string; width: number; height?: number };
} = {
  is: { source: IsCompleteImg, width: 250, height: 250 },
  connect: { source: ConnectCompleteImg, width: 250, height: 250 },
  sura: { source: SuraCompleteImg, width: 250, height: 250 },
  bcr: { source: bcrLogo, width: 300, height: 200 },
};
