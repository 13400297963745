import { useContext, useState } from 'react';
import { MediaId } from '../../context/photoConstants';
import { IAppContext, Photo } from '../../types';
import PhotoSectionScreen from '../../screens/PhotoSectionScreen';
import { AppContext } from '../../context/Context';
import { removePhotoFromPreview } from '../../helpers/photoHelper';
import Modal from '../Modal';
import './index.scss';

interface props {
  allowMenuSections: boolean;
  currentSection: string;
  links: JSX.Element[];
}

const Gallery = ({
  allowMenuSections,
  currentSection,
  links,
}: props): JSX.Element => {
  const { dispatch } = useContext(AppContext) as IAppContext;
  const [previewPhoto, setPreviewPhoto] = useState<Photo | null>(null);

  const removePhoto = async (id: MediaId, url?: string) => {
    await removePhotoFromPreview(id, dispatch, url);
    setPreviewPhoto(null);
  };

  return (
    <div id="gallery-container">
      {allowMenuSections ? (
        <>
          <h3 className="lead2">Selecciona un grupo para iniciar</h3>
          <div className="photo-section-links">{links}</div>
        </>
      ) : (
        <>
          <h3 className="lead2">Subir las siguientes fotos solicitadas:</h3>
          <PhotoSectionScreen
            idSection={currentSection}
            onPreviewClick={setPreviewPhoto}
          />
        </>
      )}

      {previewPhoto?.url && (
        <Modal
          onBackdropClick={() => setPreviewPhoto(null)}
          className="photo-preview-modal"
        >
          <img
            className="preview-photo"
            src={previewPhoto.url}
            alt="Photo preview"
            width={300}
          />
          <div className="action-buttons">
            <button
              className="button button-danger button-block button-left"
              onClick={() => removePhoto(previewPhoto.id, previewPhoto.url)}
            >
              Eliminar
            </button>
            <button
              className="button button-block button-right"
              onClick={() => setPreviewPhoto(null)}
            >
              Cerrar
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Gallery;
