import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AppProvider } from './context/Context';
import PATH_ROUTES from './static/constants/path-routes';
import InactiveScreen from './screens/InactiveScreen';
import SplashScreen from './screens/Splash';
import WelcomeScreen from './screens/WelcomeScreen';
import InfoScreen from './screens/InfoScreen';
import OnboardingScreen from './screens/Onboarding';
import PhotoSectionScreen from './screens/PhotoSectionScreen';
import VideoUploadScreen from './screens/VideoUploadScreen';
import CongratsScreen from './screens/Congrats';
import FormScreen from './screens/FormScreen';
import FinishedScreen from './screens/Finished';
import SharedScreen from './screens/SharedScreen';
import AlertDeviceScreen from './screens/AlertDeviceScreen';
import Layout from './components/layout/Layout';
import LocalizationScreen from './screens/LocalizationScreen';

const Router: React.FC = (): JSX.Element => {
  return (
    <AppProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <SplashScreen />
          </Route>
          <Route exact path={PATH_ROUTES.PRE_ALERT_DEVICE_SCREEN}>
            <AlertDeviceScreen />
          </Route>
          <Route exact path={PATH_ROUTES.PRE_INACTIVE_SCREEN}>
            <InactiveScreen />
          </Route>
          <Route exact path={PATH_ROUTES.PRE_SPLASH_SCREEN}>
            <SplashScreen />
          </Route>
          <Route exact path={PATH_ROUTES.PRE_WELCOME_SCREEN}>
            <Layout>
              <WelcomeScreen />
            </Layout>
          </Route>
          <Route exact path={PATH_ROUTES.PRE_LOCALIZATION}>
            <Layout>
              <LocalizationScreen />
            </Layout>
          </Route>
          <Route exact path={PATH_ROUTES.PRE_INFO_SCREEN}>
            <Layout>
              <InfoScreen />
            </Layout>
          </Route>
          <Route exact path={PATH_ROUTES.PRE_ONBOARDING_SCREEN}>
            <OnboardingScreen />
          </Route>
          <Route exact path={PATH_ROUTES.PRE_TAB_SECTIONS}>
            <Layout>
              <FormScreen />
            </Layout>
          </Route>
          <Route exact path={PATH_ROUTES.PRE_VIDEO_SECTION}>
            <VideoUploadScreen />
          </Route>
          <Route exact path={PATH_ROUTES.PRE_PHOTO_SECTION}>
            <PhotoSectionScreen />
          </Route>
          <Route exact path={PATH_ROUTES.PRE_CONGRATS_SCREEN}>
            <CongratsScreen />
          </Route>
          <Route exact path={PATH_ROUTES.PRE_FINISH_SCREEN}>
            <Layout>
              <FinishedScreen />
            </Layout>
          </Route>
          <Route exact path={PATH_ROUTES.PRE_SHARED_SCREEN}>
            <SharedScreen />
          </Route>
        </Switch>
      </BrowserRouter>
    </AppProvider>
  );
};

export default Router;
