import './Onboarding.scss';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useHistory } from 'react-router';
import ContinueButton from '../components/carousel/ContinueButton';
import PATH_ROUTES from '../static/constants/path-routes';
import PageIndicators from '../components/carousel/PageIndicators';
import { Pages } from '../components/carousel/Pages';
import localforage from 'localforage';
import { AppContext } from '../context/Context';
import { IAppContext } from '../types';
import { savePreInspection } from '../services/preInspect.service';
import { Alert } from '@mui/material';
import { ActionType } from '../context/actions';
import { BRAND } from '../static/constants/brands/brand';
import { getPagesToRemove, getTotalPages } from '../helpers/Utils';
import { Company } from '../interfaces/brand';
import { getPageIndex } from '../helpers/Utils';

const brandStr = BRAND;
const totalPages = getTotalPages(brandStr);

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

enum PAGE_TYPE {
  NEXT_PAGE = 'NEXT_PAGE',
  GEOLOCATION = 'GEOLOCATION',
}

const OnboardingScreen: FunctionComponent = (): JSX.Element => {
  const { state, dispatch } = useContext(AppContext) as IAppContext;
  const [permissionState, setPermissionState] = useState('');
  const [nextPage, setNextPage] = useState('');
  const [page, setPage] = useState(0);
  const [confirmPolicy, setConfirmPolicy] = useState(false);
  const ref = useRef(null);
  const history = useHistory();
  const PAGES = Pages();

  const orderedKeys = [
    getPageIndex(brandStr, 'documents'),
    getPageIndex(brandStr, 'time'),
    getPageIndex(brandStr, 'photos'),
    getPageIndex(brandStr, 'location'),
  ].map((key) => (key === '0' ? null : key));

  // Ordenar los elementos según las keys
  const orderedPages = orderedKeys.map((key) =>
    PAGES.find((page) => page.key === key),
  );

  const getGeolocation = async () => {
    const currentId: any = await localforage.getItem('preId');
    if ('geolocation' in navigator) {
      /* geolocation is available */
      setPermissionState('prompt');
      navigator.geolocation.getCurrentPosition(
        (position) => {
          dispatch({
            type: ActionType.SET_PREINSPECTION_GEOLOCATION,
            payload: {
              lat: String(position.coords.latitude),
              lng: String(position.coords.longitude),
            },
          });
          goToNextPage(page);
          setPermissionState('');

          if (currentId && String(currentId).length > 0) {
            savePreInspection(state.formData, state, dispatch, false);
            history.push(nextPage);
          } else {
            history.push(PATH_ROUTES.PRE_INACTIVE_SCREEN);
          }
        },
        () => {
          history.push(PATH_ROUTES.PRE_LOCALIZATION);
        },
      );
    } else {
      /* geolocation IS NOT available */
      history.push(PATH_ROUTES.PRE_LOCALIZATION);
    }
  };

  const goToNextPage = async (page: number): Promise<void> => {
    setPage(page + 1);
    (ref.current as any)?.goToSlide?.(page + 1);
  };

  const nextScreen = async () => {
    const pageIndex: any = {
      // [0, 1, 2 ..]
      [Company.IS]: [
        PAGE_TYPE.NEXT_PAGE,
        PAGE_TYPE.NEXT_PAGE,
        PAGE_TYPE.NEXT_PAGE,
        PAGE_TYPE.GEOLOCATION,
      ],
      [Company.SURA]: [PAGE_TYPE.NEXT_PAGE, PAGE_TYPE.GEOLOCATION],
      [Company.BCR]: [
        PAGE_TYPE.NEXT_PAGE,
        PAGE_TYPE.NEXT_PAGE,
        PAGE_TYPE.GEOLOCATION,
      ],
      [Company.CONNECT]: [
        PAGE_TYPE.NEXT_PAGE,
        PAGE_TYPE.NEXT_PAGE,
        PAGE_TYPE.NEXT_PAGE,
        PAGE_TYPE.GEOLOCATION,
      ],
    };

    const definePage = pageIndex[brandStr]?.[page];
    if (definePage !== undefined) {
      if (definePage === PAGE_TYPE.NEXT_PAGE) {
        if (Company.SURA === brandStr && page < 1) {
          setConfirmPolicy(true);
        }
        await goToNextPage(page);
      } else if (definePage === PAGE_TYPE.GEOLOCATION) {
        await getGeolocation();
      }
    }
  };

  useEffect(() => {
    const pagesArray = getPagesToRemove(brandStr);
    for (let i = 0; i < pagesArray.length; i++) {
      PAGES.splice(pagesArray[i], 1);
    }

    (async (): Promise<void> => {
      const currentId: any = await localforage.getItem('preId');
      if (currentId) {
        setNextPage(PATH_ROUTES.PRE_TAB_SECTIONS.replace(':id', currentId));
      }
    })();
  }, []);

  return (
    <div className="onboarding">
      {permissionState === 'prompt' && (
        <Alert severity="info">Habilite el permiso de geolocalización</Alert>
      )}
      {permissionState === 'denied' && (
        <Alert severity="warning">
          Debes dar acceso a tu geolocalización para seguir con el proceso.
        </Alert>
      )}

      <Carousel
        ref={ref}
        responsive={responsive}
        arrows={false}
        draggable={false}
        swipeable={Company.SURA === brandStr ? confirmPolicy : false}
        afterChange={(_, state) => setPage(state.currentSlide)}
      >
        {orderedPages}
      </Carousel>
      <PageIndicators count={totalPages} selectedIndex={page} />

      {brandStr === Company.SURA ? (
        <ContinueButton onClick={nextScreen}>
          {page < 1 ? 'Acepto' : 'Iniciar'}
        </ContinueButton>
      ) : (
        <ContinueButton onClick={nextScreen}>
          {page < totalPages - 1 ? 'Siguiente' : 'Iniciar'}
        </ContinueButton>
      )}
    </div>
  );
};

export default OnboardingScreen;
