export interface BreakpointsFlags {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

const useDevice = (): BreakpointsFlags => {
  return {
    isMobile: window.matchMedia('only screen and (max-width: 760px)').matches,
    isTablet: window.matchMedia(
      'only screen and (min-width:712px) and (max-width:1024px)',
    ).matches,
    isDesktop: window.matchMedia('only screen and (min-width: 1024px)').matches,
  };
};

export default useDevice;
