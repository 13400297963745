import './Splash.scss';
import localforage from 'localforage';
import { nanoid } from 'nanoid';
import React, { FunctionComponent, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import PATH_ROUTES from '../static/constants/path-routes';
import { AppContext } from '../context/Context';
import { IAppContext } from '../types';
import { ActionType } from '../context/actions';
import {
  getFormConfiguration,
  getPreInspection,
  getPreInspectMedia,
} from '../services/preInspect.service';
import useQuery from '../hooks/useQuery';
import { BRAND, LOGOS } from '../static/constants/brands/brand';
import noImage from '../assets/imgs/no-image.png';
import useDevice from '../hooks/useDevice';
import { brandingInfo } from '../static/constants/brands';
import { Company } from '../interfaces/brand';

const SplashScreen: FunctionComponent = (): JSX.Element => {
  const { dispatch } = useContext(AppContext) as IAppContext;
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const queryParams = useQuery();

  const requestPreInspection = () => {
    (async (): Promise<void> => {
      const resultEnv = useDevice();
      if (resultEnv.isDesktop) {
        history.push(PATH_ROUTES.PRE_ALERT_DEVICE_SCREEN);
      } else {
        const currentId: any = await localforage.getItem('preId');
        const appState = localStorage.getItem('appState');

        if (currentId && String(currentId).length > 0) {
          const objData = await getPreInspection(currentId);
          const formData = await getFormConfiguration(currentId);
          let mediaData = await getPreInspectMedia(currentId);

          if (objData.preIsInactive) {
            history.push(PATH_ROUTES.PRE_INACTIVE_SCREEN);
          } else {
            // Validate if the insurer allows anonymous pre-inspections
            if (
              [Company.IS.toString(), Company.BCR.toString()].includes(
                BRAND?.toLowerCase(),
              ) &&
              objData.preInspectionId === 0
            ) {
              history.push(PATH_ROUTES.PRE_INACTIVE_SCREEN);
            } else {
              // Detect if the current state have a backup later validate
              // if the photo backup need to load before request
              if (appState && String(appState).length > 0) {
                const stateBackup = JSON.parse(appState);
                const { preInspectionId } = stateBackup?.preInspectionModel;
                if (
                  preInspectionId &&
                  preInspectionId === objData?.preInspectionId
                ) {
                  mediaData = stateBackup?.photos ?? mediaData;
                }
              }

              dispatch({
                type: ActionType.SET_PREINSPECTION_MODEL,
                payload: objData,
              });
              dispatch({
                type: ActionType.SET_PREINSPECTION_FORM_INIT,
                payload: formData,
              });
              dispatch({
                type: ActionType.SET_PREINSPECTION_FORM,
                payload: formData,
              });
              dispatch({
                type: ActionType.SET_PREINSPECTION_MEDIA,
                payload: mediaData,
              });

              history.push(PATH_ROUTES.PRE_WELCOME_SCREEN);
            }
          }
        }
      }
    })();
  };

  const resolvePreinspection = (): void => {
    (async (): Promise<void> => {
      const { id } = params;
      let uniqueId = id;

      if (!uniqueId || uniqueId === 'generate') {
        uniqueId = nanoid();

        // Validate if the insurer allows anonymous pre-inspections
        if (
          [Company.IS.toString(), Company.BCR.toString()].includes(
            BRAND?.toLowerCase(),
          )
        ) {
          uniqueId = '';
        }
      } else {
        const storageId: string = (await localforage.getItem('preId')) ?? '';
        if (storageId && storageId.toLowerCase() !== uniqueId.toLowerCase()) {
          localStorage.removeItem('externalCarImagesIsBlurry');
          localStorage.removeItem('preIsBroker');
          localStorage.removeItem('countDeletesByInvalidImages');
          localStorage.removeItem('appState');
        }
      }

      // Clear all local data when opening the event. Prevents rewriting data with information of other events
      await localforage.clear();
      await localforage.setItem('preId', uniqueId.toLowerCase());
      dispatch({
        type: ActionType.SET_PREINSPECTION_UNIQUEID,
        payload: uniqueId.toLowerCase(),
      });
      setTimeout(requestPreInspection, 1000);
    })();
  };

  useEffect(() => {
    resolvePreinspection();
    const endText = queryParams.get('endtext');
    if (endText) {
      (async () => {
        await localforage.setItem('endtext', endText);
      })();
    }
  }, []);

  return (
    <div className="splash">
      <picture>
        <source srcSet={LOGOS[BRAND]} />
        <img src={noImage} alt="Application Logo" />
      </picture>
      <p className="footer-message">{brandingInfo[BRAND]?.footerMsg ?? ''}</p>
      <h3>
        Powered by <span>Connect Assistance</span>
      </h3>
    </div>
  );
};

export default SplashScreen;
