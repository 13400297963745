import React, {
  useEffect,
  useRef,
  useState,
  SyntheticEvent,
  useCallback,
} from 'react';
import SignatureCanvas from 'react-signature-canvas';
import useWindowDimensions from '../../helpers/useWindowDimensions';
import CloseCircleIcon from '../../assets/imgs/close.svg';
import RotateIcon from '../../assets/imgs/rotate.png';
import EditIcon from '../../assets/imgs/edit.svg';
import './Signature.scss';
import { BRAND } from '../../static/constants/brands/brand';

const PADDING = 16; // keep in sync with .scss $padding.

async function resizeImage(
  dataUrl: string,
  width: number,
  height: number,
): Promise<string> {
  const sourceImage = new Image();

  // eslint-disable-next-line no-undef
  return new Promise((resolve) => {
    sourceImage.onload = function () {
      // Create a canvas with the desired dimensions
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;

      // Scale and draw the source image to the canvas
      const canvasContext = canvas.getContext('2d');
      if (canvasContext) {
        canvasContext.drawImage(sourceImage, 0, 0, width, height);
      }

      // Convert the canvas to a data URL in PNG format
      resolve(canvas.toDataURL());
    };

    sourceImage.src = dataUrl;
  });
}

/**
 * Provides a Preview div, with edit and clear options. Hitting clear
 * will create a full-screen landscape oriented canvas to hold signature.
 *
 * NOTE: We investigated using ScreenOrientation.lock, but its not supported
 * in a good amount of devices and its considered experimental. Indeed hooks
 * like "useScreenOrientation" would break in iOS devices. We also tried
 * creating a rotated view by using CSS rotate(90deg), but it got complicated
 * quick, considering resizing/re-orientation causes the browser to clear
 * the canvas, and the canvas only works upright. So it've involved considerable
 * "magic" to redraw the signature on resizing/re-orientation and/or handling
 * before sending to server. Scrapped approach.
 *
 * CSS orientation media queries work fine on most devices.
 */

interface Props {
  signature: string | null | undefined;
  onSignatureChange: (dataUrl: string | null) => void;
  onConfirm?: () => void;
  isDisabled?: boolean;
  hasError?: boolean;
}

export const Signature = (props: Props): JSX.Element => {
  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const [showHelpMessage, setShowHelpMessage] = useState<boolean>(true);
  const [previewDataUrl, setPreviewDataUrl] = useState<string | null>(null);
  const [enableRefresh, setEnableRefresh] = useState<boolean>(false);
  const [fromPortraitMode, setFromPortraitMode] = useState<boolean>(false);

  const signatureRef = useRef<SignatureCanvas>(null);
  const { height, width } = useWindowDimensions();
  const brand = BRAND;

  const setSignature = (dataUrl: string | null): void => {
    props.onSignatureChange(dataUrl);
  };

  const onEditClick = (): void => {
    if (!props.isDisabled) {
      setInEditMode(true);
      setEnableRefresh(true);
    }
  };

  const refreshSignedView = useCallback(() => {
    if (
      previewDataUrl &&
      enableRefresh &&
      fromPortraitMode &&
      !showHelpMessage
    ) {
      if (signatureRef.current) {
        signatureRef.current.clear();
      }

      const result = signatureRef.current?.fromDataURL(previewDataUrl, {
        width: width - PADDING - PADDING,
        height: 125,
      });
      // eslint-disable-next-line no-console
      console.log('Refresh orientation ' + (result ?? ''));
      setFromPortraitMode(false);
    }
  }, [previewDataUrl, width, enableRefresh, fromPortraitMode, showHelpMessage]);

  const onClearClick = (e: SyntheticEvent): void => {
    setSignature(null);
    if (signatureRef.current) {
      signatureRef.current.clear();
    }
    setShowHelpMessage(true);
    e.stopPropagation();
  };

  const onConfirmClick = (): void => {
    setInEditMode(false);
    setEnableRefresh(false);
    if (props.onConfirm) {
      props.onConfirm();
    }
  };

  const onSignatureBegin = (): void => setShowHelpMessage(false);

  const onSignatureEnd = (): void => {
    if (signatureRef.current === null) {
      return;
    }
    const dataUrl = signatureRef.current.toDataURL();
    setSignature(dataUrl);

    if (props.onConfirm) {
      props.onConfirm();
    }
    setPreviewDataUrl(dataUrl);
  };

  useEffect(() => {
    if (props.signature !== null && props.signature !== undefined) {
      resizeImage(props.signature, width - PADDING - PADDING, 100).then(
        setPreviewDataUrl,
      );
      if (props.signature?.length > 3) {
        setShowHelpMessage(false);
      } else {
        setShowHelpMessage(true);
      }
    } else {
      setPreviewDataUrl(null);
      setShowHelpMessage(true);
    }

    // Validate if the user are in LANDSCAPE mode
    if (window.matchMedia('(orientation: landscape)').matches) {
      refreshSignedView();
    }

    // Validate if the user are in PORTRAIT mode
    if (window.matchMedia('(orientation: portrait)').matches) {
      setFromPortraitMode(true);
    }
  }, [props.signature, width, height, refreshSignedView]);

  return (
    <div className="signature">
      {inEditMode && (
        <div className="signature__landscape">
          <div className="signature__canvasContainer">
            {showHelpMessage && (
              <div className="signature__helpText">Firme con su dedo</div>
            )}
            <SignatureCanvas
              penColor="black"
              ref={signatureRef}
              canvasProps={{
                width: width * 0.75,
                height: 275,
                className: 'signature__canvas',
              }}
              clearOnResize={false}
              onBegin={onSignatureBegin}
              onEnd={onSignatureEnd}
            />
            <img
              src={CloseCircleIcon}
              className="signature__clearIcon"
              alt="clear-icon"
              onClick={onClearClick}
            />
          </div>
          <button
            disabled={props.signature === null}
            className={`signature__confirmButton ${brand}`}
            onClick={onConfirmClick}
          >
            Confirmar
          </button>
          <img
            src={RotateIcon}
            className="signature__rotateIcon"
            alt="rotate-icon"
          />
          <h2 className="signature__message">Por favor rotar su dispositivo</h2>
        </div>
      )}
      {!inEditMode && (
        <div
          className={
            props.hasError ? 'signature__preview-error' : 'signature__preview'
          }
          onClick={onEditClick}
        >
          <img src={EditIcon} className="signature__editIcon" alt="edit-icon" />
          <img
            src={CloseCircleIcon}
            className="signature__clearIcon"
            alt="clear-icon"
            onClick={onClearClick}
          />
          {previewDataUrl ? (
            <img
              src={previewDataUrl}
              alt="signature preview"
              className="signature__previewImage"
            />
          ) : (
            <div className="signature__placeholder">Toque aquí</div>
          )}
        </div>
      )}
    </div>
  );
};

export default Signature;
