import './styles.scss';
import { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from '../../../context/Context';
import {
  MediaId,
  PHOTO_DEFINITIONS,
  PHOTO_SECTIONS,
  isValidPhotoSectionsByBrand,
} from '../../../context/photoConstants';
import { IAppContext, Photo } from '../../../types';
import { Field, FieldSection, TabDataSections } from '../../../types/form';
import ContinueButton from '../../carousel/ContinueButton';
import { ReactComponent as CheckmarkIcon } from '../../../assets/imgs/photo-menu/checkmark.svg';
import {
  filterSectionsByDependencies,
  validationForm,
} from '../../../helpers/Utils';
import FieldContent from '../../../screens/FieldContent';
import PATH_ROUTES from '../../../static/constants/path-routes';
import { Alert, Button, CircularProgress, Dialog, Grid } from '@mui/material';
import {
  getPreInspection,
  savePreInspection,
  syncAlertsWithForm,
} from '../../../services/preInspect.service';
import { ActionType } from '../../../context/actions';
import ProgressLoading from '../../common/ProgressLoading';
import { Company } from '../../../interfaces/brand';
import { BRAND } from '../../../static/constants/brands/brand';
import Gallery from '../../gallery';

interface Props {
  sections: FieldSection[];
  setSections: (sections: FieldSection[]) => void;
  setTabClick: (valid: boolean) => void;
  activeStep: number;
  nextStep: any;
  tabOrder: number;
  setTabOrder: (idOrder: number) => void;
  preId: string;
  goToBackStep: () => void;
}

const TabContent = ({
  sections,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSections,
  setTabClick,
  activeStep,
  nextStep,
  tabOrder,
  setTabOrder,
  preId,
  goToBackStep,
}: Props): JSX.Element => {
  const history = useHistory();
  const { state, dispatch } = useContext(AppContext) as IAppContext;
  const [loading, setLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [confirmExternalSide, setConfirmExternalSide] =
    useState<boolean>(false);

  const isFirstTab = activeStep === 165; // First Section in tab

  const renderLinks = (): JSX.Element[] => {
    return Object.entries(PHOTO_SECTIONS).map(
      ([photoSectionId, photoSection]) => {
        let done = photoSection.photoIds
          .filter(
            (photoId) =>
              PHOTO_DEFINITIONS[photoId].isRequired === true &&
              isValidPhotoSectionsByBrand(
                photoId,
                state.preInspectionModel,
                PHOTO_DEFINITIONS[photoId].isVisible,
                BRAND,
              ),
          )
          .map((photoId) => state.photos[photoId] as Photo)
          .filter((photo) => photo !== undefined)
          .every((photo) => {
            const hasValidUrl = Boolean(photo.url);
            const isNotValid = photo.notValid;
            const isNotValidZone = photo.notValidZone;

            return (
              (hasValidUrl && !isNotValid && !isNotValidZone) ||
              (hasValidUrl && isNotValid && isNotValidZone) ||
              (hasValidUrl && isNotValid && !isNotValidZone)
            );
          });

        done = photoSection.photoIds.some(
          (p) => PHOTO_DEFINITIONS[p].isRequired,
        )
          ? done
          : false;

        const validateLinkSections = (sectionTitle: string): boolean => {
          if (BRAND === Company.IS) {
            const preIsBroker = localStorage.getItem('preIsBroker') === 'true';
            const { preActiveQuestionBroker } = state.preInspectionModel;
            if (preActiveQuestionBroker === true && preIsBroker === true) {
              return !['Video 360°'].includes(sectionTitle);
            }
          }

          return true;
        };

        return (
          <>
            {photoSection.visible && validateLinkSections(photoSection.title) && (
              <Link
                key={photoSectionId}
                className="photo-section-link"
                to={`/photo-sections/${photoSectionId}`}
              >
                <div className="label">
                  {photoSection.icon}&nbsp;&nbsp;
                  <span>{photoSection.title}</span>
                </div>
                {done && (
                  <CheckmarkIcon
                    width={30}
                    height={30}
                    className="theme-svg-icon"
                  />
                )}
              </Link>
            )}
          </>
        );
      },
    );
  };

  const filterSections = sections
    .filter((section: FieldSection) => {
      return (
        section.parentSectionId === activeStep &&
        filterSectionsByDependencies(section, sections)
      );
    })
    .sort((a, b) => (a.idOrder > b.idOrder ? 1 : -1));

  const formValidation = (): { isForm: boolean; result: any } => {
    const currentSection = sections.filter(
      (f: FieldSection) => f.parentSectionId === activeStep,
    )[0];
    const isForm =
      currentSection.name === undefined ||
      currentSection.name === null ||
      currentSection.name.length === 0 ||
      currentSection.name === TabDataSections.PRE_GENERAL ||
      currentSection.name === TabDataSections.PRE_VEHICLE ||
      currentSection.name === TabDataSections.PRE_TERMS;
    const result: any = isForm
      ? validationForm(activeStep, sections, state.formDataInit)
      : false;
    if (Company.BCR === BRAND) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(result ? !result?.valid : false);
      setTabClick(result ? result?.valid : true);
    }

    return {
      isForm: isForm,
      result: result,
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const goToFinishScreen = (): void => {
    history.replace(PATH_ROUTES.PRE_FINISH_SCREEN);
  };

  const externalPhotoGalleryIsValid = (): boolean => {
    if (Company.IS === BRAND) {
      let invalidCounts = 0;
      for (const p in state.photos) {
        if (
          [
            MediaId.VEHICLE_EXTERIOR_BACK,
            MediaId.VEHICLE_EXTERIOR_FRONT,
            MediaId.VEHICLE_EXTERIOR_LEFT,
            MediaId.VEHICLE_EXTERIOR_RIGHT,
          ].includes(p as MediaId)
        ) {
          const itemPhoto = state.photos[p as MediaId] as Photo;
          if (
            (itemPhoto.notValid && itemPhoto.notValid === true) ||
            (itemPhoto.isBlurry && itemPhoto.isBlurry === true)
          ) {
            invalidCounts++;
          }
        }
      }

      if (invalidCounts >= 3 && !state.externalCarImagesIsBlurry) {
        return false;
      }
    }

    return true;
  };

  const triggerNextStep = (): void => {
    const { isForm, result } = formValidation();
    let validForm = true;
    if (Company.BCR !== BRAND) {
      if (isForm && result && result.sections) {
        setSections(result.sections);
        setTabClick(result.valid);
      }
      validForm = result.valid;
    }

    if (!externalPhotoGalleryIsValid()) {
      setTimeout(() => {
        history.replace('/photo-sections/EXTERIOR');
      }, 3000);

      setConfirmExternalSide(true);

      return;
    } else {
      if (tabOrder < 0 && validForm) {
        (async (): Promise<void> => {
          setLoading(true);
          await syncAlertsWithForm(state, dispatch);
          await savePreInspection(
            state.formData,
            state,
            dispatch,
            true,
            goToFinishScreen,
          );
        })();
      } else if (
        nextStep &&
        nextStep.formSectionId > 0 &&
        (!isForm || (result && validForm))
      ) {
        (async (): Promise<void> => {
          const objData = await getPreInspection(preId);
          dispatch({
            type: ActionType.SET_PREINSPECTION_MODEL,
            payload: objData,
          });
        })();

        setTabOrder(nextStep.idOrder);

        history.replace(
          `/preinspections/${preId}/sections/${nextStep.formSectionId}`,
        );
      }
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (isFirstTab) {
      setBtnDisabled(
        !Object.values(state.photos as unknown as Photo)
          .filter((p: Photo | Photo[]) => {
            if (Array.isArray(p)) {
              return false;
            }

            return (
              PHOTO_DEFINITIONS[p.id].isRequired === true &&
              isValidPhotoSectionsByBrand(
                p.id,
                state.preInspectionModel,
                PHOTO_DEFINITIONS[p.id].isVisible,
                BRAND,
              )
            );
          })
          .every((photo) => photo !== undefined && Boolean(photo.url)),
      );
    } else {
      formValidation();
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // Prevent the default behavior to prevent the window from closing immediately
      event.preventDefault();

      return '';
    };

    // Add the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove the event listener when the component unmounts to avoid memory leaks
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <>
      {confirmExternalSide && (
        <Alert className="info-alert" severity="warning">
          Debes confirmar las fotos externas del vehículo. &nbsp;
          <CircularProgress color="warning" size={18} />
        </Alert>
      )}

      {loading && (
        <ProgressLoading
          messages={['Procesando información...']}
          duration={4000}
        />
      )}
      <div className="container preInspection">
        {filterSections.map((section: FieldSection, index: number) => {
          return (
            <div key={index}>
              {section.name === TabDataSections.PRE_PHOTOS && (
                <Gallery
                  links={renderLinks()}
                  allowMenuSections={Company.SURA !== BRAND}
                  currentSection={Company.SURA === BRAND ? 'SURA_PHOTOS' : ''}
                />
              )}
              {section.name === TabDataSections.PRE_TERMS && (
                <>
                  <h3 className="lead2">Términos y Condiciones</h3>
                  {section.fields
                    .sort((a, b) => a.idOrder - b.idOrder)
                    .map((dataField: Field) => {
                      return (
                        <FieldContent
                          key={dataField.idName}
                          field={dataField}
                          countryId={0}
                          isDisabled={dataField.isDisabled}
                          triggerValidation={formValidation}
                        />
                      );
                    })}
                  <Alert className="info-alert" severity="info">
                    Enviaremos copia de tu inspección al correo electrónico.
                  </Alert>
                </>
              )}
              {(String(section.name ?? '').length === 0 ||
                String(section.name ?? '') === TabDataSections.PRE_VEHICLE ||
                String(section.name ?? '') === TabDataSections.PRE_GENERAL) && (
                <>
                  <h3 className="lead">{section.title}</h3>
                  {section.fields
                    .sort((a, b) => a.idOrder - b.idOrder)
                    .map((dataField: Field) => {
                      return (
                        <FieldContent
                          key={dataField.idName}
                          field={dataField}
                          countryId={0}
                          isDisabled={dataField.isDisabled}
                          triggerValidation={formValidation}
                        />
                      );
                    })}
                </>
              )}
            </div>
          );
        })}
      </div>
      {!isFirstTab && Company.SURA === BRAND && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              className={'back-btn button'}
              onClick={goToBackStep}
            >
              Volver
            </Button>
          </Grid>
          <Grid item xs={6}>
            <ContinueButton disabled={btnDisabled} onClick={triggerNextStep}>
              {tabOrder < 0 && <>Finalizar</>}
              {tabOrder > 0 && <>Continuar</>}
            </ContinueButton>
          </Grid>
        </Grid>
      )}

      {(isFirstTab || Company.SURA !== BRAND) && (
        <ContinueButton disabled={btnDisabled} onClick={triggerNextStep}>
          {tabOrder < 0 && <>Finalizar</>}
          {tabOrder > 0 && <>Continuar</>}
        </ContinueButton>
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <Alert className="info-alert" severity="info">
          Debe aceptar los términos y condiciones.
        </Alert>
      </Dialog>
    </>
  );
};

export default TabContent;
