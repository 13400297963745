import './FormScreen.scss';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { filterTabs } from '../helpers/Utils';
//import { sectionsDummy } from '../static/dummy';
import { FieldSection, TabDataSections } from '../types/form';
import { AppContext } from '../context/Context';
import { IAppContext, Photo, SelectOption } from '../types';
import PATH_ROUTES from '../static/constants/path-routes';
import TabContent from '../components/tabSection/tabContent';
import TabSteps from '../components/tabSection/tabSteps';
import { Company } from '../interfaces/brand';
import { BRAND } from '../static/constants/brands/brand';

const FormScreen: FunctionComponent = (): JSX.Element => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { state } = useContext(AppContext) as IAppContext;
  const { id, sectionId } = useParams<any>();
  const [menuSteps, setMenuSteps] = useState<FieldSection[]>([]);
  const [menuContent, setMenuContent] = useState<FieldSection[]>([]);
  const [nextStep, setNextStep] = useState<FieldSection | null>(null);
  const [currentTabOrder, setCurrentTabOrder] = useState(0);
  const [enableTabClick, setEnableTabClick] = useState(true);

  let filteredTabs = filterTabs(menuSteps, menuContent);
  const tab = filteredTabs.find((t) => t.formSectionId === Number(sectionId));

  const activeStep: SelectOption = {
    formSectionId: Number(sectionId),
    title: '',
    idOrder: 0,
    hasError: Boolean(tab?.hasError),
  };

  const steps = filteredTabs
    .filter((f: FieldSection) => f.level === '1')
    .map((ft: any) => {
      const sOption: SelectOption = {
        title: ft.title,
        formSectionId: ft.formSectionId,
        idOrder: ft.idOrder,
        isSelected: false,
        hasError: ft.hasError,
      };

      return sOption;
    });

  const updateTabOrder = () => {
    if (enableTabClick) {
      const listTabs = filteredTabs
        .filter(
          (f: FieldSection) =>
            f.level === '1' &&
            f.formSectionId !== Number(sectionId) &&
            f.idOrder > currentTabOrder,
        )
        .sort((a, b) => (a.idOrder > b.idOrder ? 1 : -1));

      const nextTab =
        listTabs.length > 0
          ? listTabs.reduce((prev, current) => {
              return prev.idOrder < current.idOrder ? prev : current;
            })
          : null;

      if (nextTab === null) {
        setCurrentTabOrder(-1);
      }

      setNextStep(nextTab);

      if (Company.SURA !== BRAND || (nextTab && nextTab.idOrder === 1)) {
        setCurrentTabOrder(nextTab ? nextTab.idOrder : -1);
      }
    }
  };

  const triggerTabClick = (tabStep: SelectOption) => {
    const enableTesting = process.env.REACT_APP_ENABLE_TESTING || '0';
    const enableRequest =
      enableTesting === '1' ||
      (enableTabClick &&
        Object.values(state.photos as unknown as Photo).every((photo: Photo) => Boolean(photo.url)));
    if (enableRequest) {
      setCurrentTabOrder(tabStep.idOrder);
      history.replace(
        `/preinspections/${id}/sections/${tabStep.formSectionId}`,
      );
    }
  };

  const backStep = (): void => {
    const backTab = steps
      .sort((a, b) => (a.idOrder > b.idOrder ? 1 : -1))
      .filter(
        (f) =>
          (currentTabOrder < 0 && f.idOrder < 4) || f.idOrder < currentTabOrder,
      )
      .slice(-1);

    const findNextStep = steps
      .sort((a, b) => (a.idOrder > b.idOrder ? 1 : -1))
      .filter((f) => f.idOrder > backTab[0].idOrder)[0];

    const nextTab = filteredTabs.filter(
      (f: FieldSection) => f.formSectionId === findNextStep.formSectionId,
    )[0];

    setNextStep(nextTab);
    setCurrentTabOrder(backTab ? backTab[0].idOrder : -1);
    history.replace(
      `/preinspections/${id}/sections/${backTab[0].formSectionId}`,
    );
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [nextStep]);

  useEffect(() => {
    if (state.formData) {
      setMenuSteps(
        state.formData
          .filter(
            (section: FieldSection) =>
              section.level === '1' &&
              section.name !== TabDataSections.PRE_RESUME,
          )
          .sort((a: any, b: any) => (a.idOrder > b.idOrder ? 1 : -1)),
      );

      setMenuContent(
        state.formData.filter((section: any) => section.level === '2'),
      );
    } else {
      const { id } = params;
      history.replace(PATH_ROUTES.PRE_SPLASH_SCREEN.replace(':id', id));
    }
  }, []);

  useEffect(() => {
    // route section redirect
    if (filteredTabs?.length && (!sectionId || sectionId === ':sectionId')) {
      const firstSectionId = filteredTabs[0].formSectionId;
      history.push(`/preinspections/${id}/sections/${firstSectionId}`);
    }
  }, [history, sectionId, id, filteredTabs]);

  useEffect(() => {
    if (filteredTabs?.length > 0) {
      updateTabOrder();
    } else if (menuSteps.length > 0 && menuContent.length > 0) {
      filteredTabs = filterTabs(menuSteps, menuContent);
    }
  }, [sectionId, menuSteps, menuContent]);

  return (
    <div className="form-screen">
      <div className="sections-selector">
        <TabSteps
          steps={steps}
          activeStep={activeStep}
          onSelectedTab={triggerTabClick}
        />
      </div>
      {activeStep.formSectionId > 0 && menuContent.length > 0 && (
        <TabContent
          key={`tbc_${activeStep.formSectionId}`}
          sections={menuContent}
          setSections={setMenuContent}
          setTabClick={setEnableTabClick}
          activeStep={activeStep.formSectionId}
          nextStep={nextStep}
          tabOrder={currentTabOrder}
          setTabOrder={setCurrentTabOrder}
          preId={id}
          goToBackStep={backStep}
        />
      )}
    </div>
  );
};

/**
 * Used to unmount and mount the component whenever
 * the event id is changed from within the same component
 * @returns JSX.Element
 */
const FormScreenWrapper: React.FC = () => {
  const { id } = useParams() as any;
  const [renderChild, setRenderChild] = useState(false);
  const ref = useRef(); // used to safe previous value

  useEffect(() => {
    if (id !== ref.current) {
      setRenderChild(false);
      ref.current = id;
      setTimeout(() => setRenderChild(true), 10);
    } else {
      setRenderChild(true);
    }
  }, [id]);

  return renderChild ? <FormScreen /> : <></>;
};

export default FormScreenWrapper;
