import './AlertDeviceScreen.scss';
import React, { FunctionComponent } from 'react';
import { BRAND, LOGOS } from '../static/constants/brands/brand';
import screenImage from '../assets/imgs/require-mobile-device.svg';
import noImage from '../assets/imgs/no-image.png';

const AlertDeviceScreen: FunctionComponent = (): JSX.Element => {
  return (
    <div className="alert-env">
      <picture id="logo">
        <source srcSet={LOGOS[BRAND]} />
        <img src={noImage} alt="Application Logo" />
      </picture>
      <picture id="screen-img">
        <source srcSet={screenImage} />
        <img src={noImage} alt="Screen Image" />
      </picture>
      <p>
        Se requiere seguir el proceso en un <b>dispositivo móvil</b>.
      </p>
      <h3>
        Powered by <span>Connect Assistance</span>
      </h3>
    </div>
  );
};

export default AlertDeviceScreen;
