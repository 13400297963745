export enum ActionType {
  SET_PREINSPECTION_ID = 'SET_PREINSPECTION_ID',
  SET_PREINSPECTION_UNIQUEID = 'SET_PREINSPECTION_UNIQUEID',
  SET_PREINSPECTION_MODEL = 'SET_PREINSPECTION_MODEL',
  SET_PREINSPECTION_FORM = 'SET_PREINSPECTION_FORM',
  SET_PREINSPECTION_FORM_INIT = 'SET_PREINSPECTION_FORM_INIT',
  SET_PREINSPECTION_MEDIA = 'SET_PREINSPECTION_MEDIA',
  SET_PREINSPECTION_GEOLOCATION = 'SET_PREINSPECTION_GEOLOCATION',
  ADD_PHOTO = 'ADD_PHOTO',
  UPDATE_PHOTO_URL = 'UPDATE_PHOTO_URL',
  REMOVE_PHOTO = 'REMOVE_PHOTO',
  ATTEMPT_PHOTO = 'ATTEMPT_PHOTO',
  NOTVALID_PHOTO = 'NOTVALID_PHOTO',
  SET_ALERT_MODEL = 'SET_ALERT_MODEL',
  SET_EXTRACTED_PLATE = 'SET_EXTRACTED_PLATE',
  SET_EXTRACTED_VIN = 'SET_EXTRACTED_VIN',
  SET_EXTRACTED_RUV = 'SET_EXTRACTED_RUV',
  SET_VEHICLE_SIDE_VALIDATION = 'SET_VEHICLE_SIDE_VALIDATION',
  SET_FINISH_STATUS = 'SET_FINISH_STATUS',
  SET_IS_BROKER = 'SET_IS_BROKER',
  SET_EXTERNAL_CAR_IMAGES_IS_BLURRY = 'SET_EXTERNAL_CAR_IMAGES_IS_BLURRY',
}
